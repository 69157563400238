<template>
  <div v-if="items && items.length" class="news-item-page__disputable">
    <h2>Обсуждаемое</h2>
    <ul class="news-item-page__disputable-items">
      <li v-for="(item, i) in items" :key="i">
        <CardComponent :item="item" />
      </li>
    </ul>
  </div>
</template>

<script>
import CardComponent from "components/CardComponent.vue";
export default {
  name: "NewsDisputableComponent",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    CardComponent,
  },
};
</script>

<style lang="stylus">
.news-item-page {

  &__disputable {
    display flex
    flex-direction column
    gap 40px
  }

  &__disputable-items {
    display grid
    grid-template-columns repeat(4, 1fr)
    gap 40px

    +below(1300px) {
      grid-template-columns repeat(3, 1fr)
    }
    +below(900px) {
      grid-template-columns repeat(2, 1fr)
    }
    +below(550px) {
      grid-template-columns repeat(1, 1fr)
    }
  }
}
</style>
