<template>
  <main class="media-item-page page">
    <BreadcrumbsComponent :title="news?.title" :links="links" />
    <div class="container-padding media-item-page__wrapper">
      <div v-if="news" class="media-item-page__inner">
        <NewsAsideComponent />
        <div class="media-item-page__body">
          <EditorJSComponent
            v-if="$options.filters.editor_has_text(news.description)"
            :text="news.description"
          />
          <NewsSliderComponent
            v-if="news.images && news.images.length"
            class="home-page__main-slider"
            :items="news.images"
            v-slot="{ item }"
          >
            <NewsSlideComponent :data="item" />
          </NewsSliderComponent>
        </div>
      </div>
      <NewsAnnounceComponent :items="also_news" />
      <NewsDisputableComponent :items="discussed_news" />
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import NewsSlideComponent from "./components/NewsSlideComponent.vue";
import NewsSliderComponent from "./components/NewsSliderComponent.vue";
import NewsAsideComponent from "./components/NewsAsideComponent.vue";
import NewsAnnounceComponent from "./components/NewsAnnounceComponent.vue";
import NewsDisputableComponent from "./components/NewsDisputableComponent.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";
import NEWS_OPEN_PAGE from "gql/pages/NewsOpenPage.graphql";

export default {
  name: "NewsItemPage",
  async asyncData({ apollo, store, route }) {
    await apollo.defaultClient
      .query({
        query: NEWS_OPEN_PAGE,
        variables: {
          id: parseInt(route.params.id),
        },
      })
      .then(({ data }) => {
        store.dispatch("news/save", data);
      })
      .catch(() => {});
  },
  computed: {
    news() {
      return this.$store.state.news.news_item;
    },
    also_news() {
      return this.$store.state.news.also_news?.data;
    },
    discussed_news() {
      return this.$store.state.news.discussed_news?.data;
    },
    links() {
      return [
        {
          title: "Пресс-центр",
          to: { name: "media" },
        },
        {
          title: "Медиа",
          to: { name: "media" },
        },
      ];
    },
  },
  components: {
    EditorJSComponent,
    NewsDisputableComponent,
    NewsAnnounceComponent,
    BreadcrumbsComponent,
    NewsAsideComponent,
    NewsSliderComponent,
    NewsSlideComponent,
  },
};
</script>

<style lang="stylus">
.media-item-page {
  padding 15px 0 60px 0
  background var(--main-1-gray-light)

  &__wrapper {
    display flex
    flex-direction column
    gap 60px
  }

  &__inner {
    display grid
    grid-gap 40px
    grid-template-columns 320px 1fr

    +below(870px) {
      display flex
      flex-direction column
    }
  }
}
</style>
