<template>
  <div class="media-slider" v-if="items && items.length">
    <div class="media-slider__items">
      <div class="swiper media-slider__main-slider" ref="mySwiper2">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, i) in items" :key="i">
            <slot :item="item"></slot>
          </div>
        </div>
        <div class="media-slider-button-next">
          <IconComponent name="arrow_down" />
        </div>
        <div class="media-slider-button-prev">
          <IconComponent name="arrow_down" />
        </div>
      </div>
      <div class="swiper media-slider__subtitle-slider" ref="mySwiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, i) in items" :key="i">
            <slot :item="item"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "SliderComponent",
  props: {
    title: String,
    buttonTitle: String,
    items: Array,
  },
  data() {
    return {
      swiper: null,
      swiper2: null,
      swiperOptions: {
        slidesPerView: 4,
        spaceBetween: 15,
        pagination: false,
        clickable: false,
      },
    };
  },
  computed: {
    activeIndex() {
      if (this.swiper) {
        return this.swiper.realIndex;
      }
      return 0;
    },
  },
  mounted() {
    require(["swiper/swiper-bundle.esm.browser.js"], ({ Swiper }) => {
      this.swiper = new Swiper(this.$refs.mySwiper, {
        loop: true,
        spaceBetween: 10,
        slidesPerView: 4,
        freeMode: true,
        watchSlidesProgress: true,
        breakpoints: {
          0: {
            slidesPerView: 1,
          },
          300: {
            slidesPerView: 2,
          },
          400: {
            slidesPerView: 3,
          },
          480: {
            slidesPerView: 4,
          },
        },
      });
      this.swiper2 = new Swiper(this.$refs.mySwiper2, {
        loop: true,
        spaceBetween: 10,
        navigation: {
          nextEl: ".media-slider-button-next",
          prevEl: ".media-slider-button-prev",
        },
        thumbs: {
          swiper: this.swiper,
        },
      });
    });
  },
  methods: {
    getCurrentBreakpoint() {
      if (this.sliderOptions.breakpoints && typeof window !== "undefined") {
        let returnCandidate = this.sliderOptions;
        Object.keys(this.sliderOptions.breakpoints).forEach((key) => {
          if (window.innerWidth > parseInt(key)) {
            returnCandidate = this.sliderOptions.breakpoints[key];
          }
        });
        return returnCandidate;
      }
      return this.sliderOptions;
    },
  },
  components: { IconComponent },
};
</script>

<style lang="stylus">
@import "../../../../node_modules/swiper/swiper-bundle.min.css"


.media-slider-button-next {
  display flex
  justify-content center
  align-items center
  position absolute
  transform rotate(-90deg)
  top 50%
  right 30px
  width 50px
  height 50px
  cursor pointer
  opacity 0.5
  background white
  z-index 10
  .icon {
    width 12px
    height 12px
    svg path {
      fill var(--dark)
    }
  }
}
.media-slider-button-prev {
  display flex
  justify-content center
  align-items center
  position absolute
  cursor pointer
  top 50%
  left 30px
  transform rotate(90deg)
  width 50px
  height 50px
  background white
  opacity 0.5
  z-index 10
  .icon {
    width 12px
    height 12px
    svg path {
      fill var(--dark)
    }
  }
}

.media-slider {

  &__items {
    display flex
    flex-direction column
    gap 15px
  }

  &__main-slider.swiper {
    height 601px
    +below(700px) {
      height 450px
    }
  }

  &__subtitle-slider.swiper {
    height 100px
    +above(500px) {
      width 405px
    }

    .swiper-slide-thumb-active {
      border: 3px inset var(--main);
    }
  }

}

.swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  height: 80%;
  width: 100%;
}

.mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
